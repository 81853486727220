import React, { useState } from "react";
import coin from "./assets/new-2.1.png";
import { FcLock } from "react-icons/fc";
import { GiWineGlass, GiPriceTag } from "react-icons/gi";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useParams } from "react-router-dom";
import axios from "axios";
import img_info_icons from "./assets/propert-info-legends.png";
import plogo from "./assets/plogo.png";

export default function NftLandingPage() {
  let { publicKey } = useParams();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPasswordBox, setShowPasswordBox] = useState(false);

  const handleEvent = async () => {
    setLoading(true);

    try {
      console.log("publicKey", publicKey);
      console.log("password", password);
      // Fetch available wallet public keys
      const response = await axios.get(
        `https://api2.ezy3.io/api/users?filters[wallet][publicKey]=${publicKey}`
      );
      const data = response.data;
      // setPublicKey(data[0]?.wallet?.publicKey);

      // Update user's phone number with selected public key
      const userId = data[0]?.id;
      await axios.put(`https://api2.ezy3.io/api/users/${userId}`, {
        // phoneNumber,
        password: password,
      });

      // setShowPaypal(true);
      window.open(`https://goerli.etherscan.io/address/${publicKey}`, "_self");
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }

    // setSent(true);
  };

  return (
    <section className="container mx-auto bg-[#FFF7F8]">
      <div className="bg-white shadow-lg py-5 rounded-b-[2.2rem]">
        <img src={plogo} alt="bg" className=" mx-auto" />
      </div>
      <div className="grid text-center  px-[36px] bgye my-10">
        <h1 className="font-medium text-[32px] text-[#0D1638]">
          Congratulations!
        </h1>
        {/* <p className="text-[20px]">
          your <span className="font-semibold">NFT</span> has landed
        </p> */}
        <p className="text-[16px]">
          You now own this <span className="font-bold">piece</span> of property.
        </p>

        <div className="flex justify-center">
          <img src={coin} alt="coin" className="mt-10 mb-5" />
        </div>

        <div className="flex justify-center">
          <img src={img_info_icons} alt="coin" className="mb-5" />
        </div>
        {/* <p className="text-[15px]">
          This is a <span className="font-bold">unique</span> digital reminder
          of your Pacific Airshow 2023 attendance. Unlock the following{" "}
        </p> */}
        {/* <span className="font-bold mt-8 mb-5">Benefits:</span> */}
      </div>{" "}
      <div className="mt-8 bg-white rounded-t-[2.2rem] shadow-black shadow-2xl pt-10 pb-10 min-h-[30vh]">
        <div className="flex justify-center items-center">
          <button
            className="rounded-lg bg-[#3489DB] text-white px-4 py-2 w-full mx-10 h-16 font-medium"
            onClick={() =>
              window.open("https://proprt-io-react-app-tester.vercel.app")
            }
          >
            View in PROPRT.io
          </button>
        </div>
        <p
          className="text-center text-[#3489DB] mt-5 underline font-medium"
          onClick={() =>
            window.open(
              `https://goerli.etherscan.io/address/${publicKey}`,
              "_self"
            )
          }
        >
          View in Etherscan
        </p>
      </div>
    </section>
  );
}
